import * as React from 'react'

function createElement(name: string): any {
  const CreateElement: React.SFC<any> = (props) => {
    return React.createElement(
      name,
      props,
      // props.children
    )
  }

  CreateElement.displayName = name

  CreateElement.defaultProps = {
    children: undefined,
  }

  return CreateElement
}

const Svg = createElement('svg')

export const Circle = createElement('circle')
export const ClipPath = createElement('clipPath')
export const Defs = createElement('defs')
export const Ellipse = createElement('ellipse')
export const G = createElement('g')
export const Image = createElement('image')
export const Line = createElement('line')
export const LinearGradient = createElement('linearGradient')
export const Path = createElement('path')
export const Polygon = createElement('polygon')
export const Polyline = createElement('polyline')
export const RadialGradient = createElement('radialGradient')
export const Rect = createElement('rect')
export const Stop = createElement('stop')
export const Symbol = createElement('symbol')
export const Text = createElement('text')
export const TextPath = createElement('textPath')
export const TSpan = createElement('tspan')
export const Use = createElement('use')

export { Svg }
export default Svg
