import * as React from 'react'
import { Svg, Line } from 'react-native-svg'
// import LinearGradient from 'react-native-linear-gradient';
import {
  View,
  StyleSheet,
  LayoutChangeEvent,
  ViewStyle,
  StyleProp,
} from 'react-native'

export interface DoNotViewState {
  width: number
  height: number
}
export interface DoNotViewProps {
  width?: number
  height?: number
  size?: number
  // color: string
  backgroundColor: string
  lineColor: string
  lineWidth: number
  containerViewStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
}

export class DoNotView extends React.PureComponent<
  DoNotViewProps,
  DoNotViewState
> {
  static defaultProps = {
    // color: 'black',
    backgroundColor: 'transparent',
    lineColor: '#F33',
    lineWidth: 4,
  }
  constructor(props: DoNotViewProps) {
    super(props)
    this.state = {
      width: 0,
      height: 0,
    }
  }

  _onLayout: ((event: LayoutChangeEvent) => void) = ({
    nativeEvent: {
      layout: { width, height },
    },
  }) => {
    this.setState({ width, height })
  }
  render() {
    const {
      backgroundColor,
      lineColor,
      lineWidth,
      containerViewStyle,
      style,
    } = this.props
    const { width, height } = this.state
    return (
      <View style={containerViewStyle}>
        <View onLayout={this._onLayout}>
          <View style={style}>{this.props.children}</View>
        </View>
        <View style={StyleSheet.absoluteFill}>
          <Svg
            width="100%"
            height="100%"
            viewBox={`0 0 ${width} ${height}`}
            style={{ backgroundColor }}
          >
            <Line
              id="DoNotLine"
              x1="0"
              y1="0"
              x2={width}
              y2={height}
              stroke={lineColor}
              strokeWidth={lineWidth}
            />
          </Svg>
        </View>
      </View>
    )
  }
}
