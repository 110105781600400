import React from 'react'
import { View, ViewProps, ViewStyle } from 'react-native'

interface SpaceProps extends ViewProps {
  width?: number
  height?: number
  flex?: number
  flexDirection?: ViewStyle['flexDirection']
}
export class Space extends React.PureComponent<SpaceProps> {
  render() {
    const { width, height, flex, flexDirection, ...otherProps } = this.props
    const style = {
      ...(width && { minWidth: width, height: '100%' }),
      ...(height && { minHeight: height, width: '100%' }),
      ...(flex && { flex, flexDirection }),
    }
    return <View style={style} {...otherProps} />
  }
}
