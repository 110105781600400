import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from 'react-native-animatable'
import { LinearGradient } from '@src/components/decorations/LinearGradient'
import { withMetrics } from '@src/design/withMetrics'

interface HeaderCatchProps {
  title: string
  headerText: string
  description: string
}

export const HeaderCatch = withMetrics<HeaderCatchProps>(
  ({ title, headerText, description, theme }) => {
    const isLargeScreen = theme.windowSizeKey > 1
    return (
      <View>
        <LinearGradient
          style={isLargeScreen ? styles.bg : styles.bg_small}
          // colors={['#FC6D01', '#FF8E00']}
          colors={['#a24600', '#FF8E00']}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 1 }}
          // style={{ flex: 1 }}
        >
          {/* <View style={styles.bg}> */}
          <Text animation="fadeInUp" style={styles.Title}>
            {title}
          </Text>
          <Text
            animation="fadeInUp"
            delay={100}
            style={isLargeScreen ? styles.HeaderText : styles.HeaderText_s}
          >
            {headerText}
          </Text>
          <Text
            animation="fadeInUp"
            delay={200}
            style={isLargeScreen ? styles.Description : styles.Description_s}
          >
            {description}
          </Text>
          {/* </View> */}
        </LinearGradient>
      </View>
    )
  },
)
const styles = StyleSheet.create({
  bg: {
    // backgroundColor: colors.theme,
    minHeight: 150,
    paddingVertical: 40,
    paddingHorizontal: '7%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bg_small: {
    // backgroundColor: colors.theme,
    minHeight: 150,
    paddingVertical: 40,
    paddingHorizontal: '4%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Title: {
    color: 'white',
    fontSize: 14,
  },
  HeaderText: {
    marginTop: 20,
    color: 'white',
    fontSize: 36,
  },
  HeaderText_s: {
    marginTop: 20,
    color: 'white',
    fontSize: 24,
  },
  Description: {
    marginTop: 16,
    color: 'white',
    fontSize: 20,
    fontWeight: '300',
  },
  Description_s: {
    marginTop: 16,
    color: 'white',
    fontSize: 16,
    fontWeight: '300',
  },
})
