import React from 'react'
import { LinearGradient } from '@src/components/decorations/LinearGradient'
import { LinearGradientProps } from 'react-native-linear-gradient'

export class BackGroundGradation extends React.PureComponent<
  LinearGradientProps
> {
  static defaultProps = {
    style: { flex: 1 },
    colors: ['#FC6D01', '#FF8E00'],
  }
  render() {
    const { style, colors } = this.props
    return (
      <LinearGradient
        testID={this.props.testID}
        colors={colors}
        // colors={['#a24600', '#bb6800']}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
        style={style}
      >
        {this.props.children}
      </LinearGradient>
    )
  }
}
