import * as React from 'react'
import { View, Text, StyleSheet, ViewStyle } from 'react-native'

interface ColorCardProps {
  name: string
  colorCode: string
  size: number
  width?: number
  border?: boolean
  containerStyle?: ViewStyle
}

export class ColorCard extends React.PureComponent<ColorCardProps> {
  static defaultProps = {
    size: 150,
    border: false,
  }
  render() {
    const { colorCode, name, size, border, containerStyle, width } = this.props

    return (
      <View style={[styles.container, containerStyle]}>
        <View
          style={[
            {
              backgroundColor: colorCode,
              width: width ? width : size,
              height: size,
            },
            border && styles.colorBorder,
          ]}
        />
        <View style={styles.border}>
          <Text style={styles.name}>{name}</Text>
          <Text style={styles.colorCode}>{colorCode}</Text>
        </View>
      </View>
    )
  }
}
const styles = StyleSheet.create({
  container: {
    // margin: 8,
  },
  colorBorder: {
    // borderTopWidth: 1,
    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    borderWidth: 1,
    borderColor: '#DDD',
  },
  border: {
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: '#DDD',
    padding: 8,
  },
  name: {
    // paddingHorizontal: 8,
    fontWeight: '500',
  },
  colorCode: {
    // paddingHorizontal: 8,
    paddingTop: 12,
    color: '#999',
    textAlign: 'right',
  },
})
