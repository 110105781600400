import * as React from 'react'
import { MetricsConsumer, MetricsContextIF } from './MetricsContext'

export interface withMetricsProps {
  theme: MetricsContextIF
}

export function withMetrics<P extends {}>(
  Component: React.ComponentType<P & withMetricsProps>,
): React.ComponentType<P> {
  const WrappedComponent: any = ({ forwardedRef, ...props }: any) => (
    <MetricsConsumer>
      {(theme) => <Component ref={forwardedRef} {...props} theme={theme} />}
    </MetricsConsumer>
  )
  return React.forwardRef((props, ref) => (
    <WrappedComponent {...props} forwardedRef={ref} />
  )) as any
}
