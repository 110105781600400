import * as React from 'react'
import { View, ViewStyle } from 'react-native'
import { LogoIcon } from './LogoIcon'
import { colors } from '@src/theme/colors'
import { LinearGradientProps } from 'react-native-linear-gradient'

export interface LogoProps extends Partial<LinearGradientProps> {
  size: number
  color: string
  backgroundColor: string
  border: boolean
  borderRadius: boolean
  style?: ViewStyle
  WrapperComponent: React.ComponentType<any>
  margin: number
}
export class Logo extends React.PureComponent<LogoProps, any> {
  static defaultProps = {
    color: 'white',
    backgroundColor: colors.theme,
    border: false,
    borderRadius: false,
    WrapperComponent: View,
    margin: 8,
  }
  constructor(props: LogoProps) {
    super(props)
  }

  render() {
    const {
      size,
      color,
      backgroundColor,
      border,
      borderRadius,
      margin,
      style,
      WrapperComponent,
      ...otherProps
    } = this.props

    return (
      <WrapperComponent
        style={[
          {
            width: size,
            height: size,
            margin,
            backgroundColor,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: borderRadius ? size * 0.15 : 0,
          },
          border && {
            borderColor: '#DDD',
            borderWidth: 1,
          },
          style,
        ]}
        {...otherProps}
      >
        <LogoIcon
          backgroundColor="transparent"
          height={size * 0.6}
          color={color}
        />
      </WrapperComponent>
    )
  }
}
