import * as React from 'react'
import { View, ViewStyle, ViewProps, StyleProp } from 'react-native'

export interface RowProps extends ViewProps {
  style: StyleProp<ViewStyle>
  wrap: boolean
}

export interface RowState {}

export class Row extends React.Component<RowProps, RowState> {
  static defaultProps = {
    style: {},
    wrap: true,
  }

  constructor(props: RowProps) {
    super(props)

    this.state = {}
  }

  render() {
    const { style, wrap, ...otherProps } = this.props
    const containerStyle: ViewStyle = {
      flexDirection: 'row',
      flexWrap: wrap ? 'wrap' : 'nowrap',
    }
    return (
      <View style={[containerStyle, style]} {...otherProps}>
        {this.props.children}
      </View>
    )
  }
}
