import React from 'react'
import {
  StyleSheet,
  ViewStyle,
  TouchableWithoutFeedbackProps,
  TextStyle,
  TouchableOpacity as Touchable,
} from 'react-native'
import { View, Text } from 'react-native-animatable'
// import { Link } from 'gatsby'

// interface ButtonDefaultProps {}
// const defaultProps: ButtonDefaultProps = {}
export interface ButtonProps {
  onPress: () => void
  style?: ViewStyle
  containerStyle?: ViewStyle
  innerViewStyle?: ViewStyle
  children?: any
  type: 'default'
  title?: string

  textStyle?: undefined
  hoverStyle?: undefined
  hoverTextStyle?: undefined
}
export interface TextTypeButtonProps {
  onPress?: TouchableWithoutFeedbackProps['onPress']
  style?: ViewStyle
  hoverStyle?: ViewStyle
  children?: undefined
  type: 'text'
  title: string
  textStyle?: TextStyle
  hoverTextStyle?: TextStyle

  containerStyle?: undefined
  innerViewStyle?: undefined
}
interface ButtonState {
  hover: boolean
}

// TODO: [check] <View> necessary inside Touchable?
export class Button extends React.PureComponent<
  ButtonProps | TextTypeButtonProps,
  ButtonState
> {
  static defaultProps = {
    type: 'default',
  }
  constructor(props: ButtonProps | TextTypeButtonProps) {
    super(props)
    this.state = {
      hover: false,
    }
  }

  _onMouseEnter = () => {
    this.setState({ hover: true })
  }
  _onMouseLeave = () => {
    this.setState({ hover: false })
  }

  render() {
    const {
      type,
      title,
      style,
      onPress,
      children,
      innerViewStyle,
      containerStyle,
      textStyle,
      hoverStyle,
      hoverTextStyle,
    } = this.props
    const { hover } = this.state
    if (type === 'text') {
      return (
        <Touchable onPress={onPress}>
          <div
            onMouseEnter={this._onMouseEnter}
            onMouseLeave={this._onMouseLeave}
          >
            <View
              style={[styles.textTypeContainer, style, hover && hoverStyle]}
              transition={['backgroundColor'] as any}
            >
              <Text style={[styles.title, textStyle, hover && hoverTextStyle]}>
                {title}
              </Text>
            </View>
          </div>
        </Touchable>
      )
    } else {
      return (
        <Touchable
          onPress={onPress}
          style={[styles.container, style, containerStyle]}
        >
          <View style={[{ justifyContent: 'center' }, style, innerViewStyle]}>
            {children}
          </View>
        </Touchable>
      )
    }
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
  },
  textTypeContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 8,
    minWidth: 36,
  },
  title: {
    // fontWeight: 'bold',
  },
})
