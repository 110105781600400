// https://sentry.io/branding/

import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { withI18n, Trans, withI18nProps } from '@lingui/react'
import { Text, View } from 'react-native'
import { Row } from '@src/components/layout/Row'
import { HeaderCatch } from '@src/components/organisms/HeaderCatch'
import { Section } from '@src/components/organisms/Section'
import { ColorCard } from '@src/components/organisms/ColorCard'
import { colors } from '@src/theme/colors'
import { Space } from '@src/components/atoms/Space'
import { LogoIcon } from '@src/components/atoms/LogoIcon'
import { DoNotView } from '@src/components/atoms/DoNotView'
import { Button } from '@src/components/atoms/Button'
import { Logo } from '@src/components/atoms/Logo'
import { BackGroundGradation } from '@src/components/decorations/BackGroundGradation'
import { withMetrics } from '@src/design/withMetrics'

const BrandGuidelinesPage = withI18n()(
  withMetrics<withI18nProps & { data: any }>(({ theme, i18n, data }) => {
    const isLargeScreen = theme.windowSizeKey > 1
    const logoGuideImageSize = isLargeScreen ? 300 : 200
    const logoIconSize = isLargeScreen ? 150 : 120

    const doNotListMargin = 8
    const doNotListData = [
      { text: i18n.t`Alter or distort the logo in any way` },
      { text: i18n.t`Alter color, shape, or angles` },
      { text: i18n.t`Rotate or flip in any direction` },
      { text: i18n.t`Alter proportions, positioning, or placement` },
      { text: i18n.t`Replace the logotype with a different typeface` },
      { text: i18n.t`Use the logo on an off-brand background` },
      { text: i18n.t`Overlap or crowd the logo with other elements` },
      { text: i18n.t`Redraw the logo` },
    ]
    const doNotList = [
      {
        title: 'color',
        element: <LogoIcon color={colors.black} height={90} />,
      },
      {
        title: 'rotate',
        element: (
          <View style={{ transform: [{ rotate: '20deg' }] }}>
            <LogoIcon color={colors.theme} height={90} />
          </View>
        ),
      },
      {
        title: 'distort',
        element: (
          <View style={{ transform: [{ scaleY: 1.5 }] }}>
            <LogoIcon color={colors.theme} height={90} />
          </View>
        ),
      },
      {
        title: 'background color',
        style: { backgroundColor: colors.gray },
        element: <LogoIcon color={colors.white} height={90} />,
      },
      {
        title: 'background color',
        style: { backgroundColor: colors.darkGreen },
        element: <LogoIcon color={colors.theme} height={90} />,
      },
      {
        title: 'background color',
        style: { backgroundColor: colors.green },
        element: <LogoIcon color={colors.theme} height={90} />,
      },
      {
        title: 'fat',
        element: (
          <LogoIcon
            strokeWidth={5}
            stroke={colors.theme}
            color={colors.theme}
            height={90}
          />
        ),
      },
      {
        title: 'border',
        element: (
          <LogoIcon
            strokeWidth={2}
            stroke={colors.black}
            color={colors.theme}
            height={90}
          />
        ),
      },
      {
        title: 'overlap',
        element: (
          <View>
            <LogoIcon color={colors.theme} height={90} />
            <Text>is great App</Text>
          </View>
        ),
      },
      {
        title: 'no spaces',
        element: <LogoIcon color={colors.theme} height={120} />,
      },
      {
        title: 'use in context',
        element: (
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', padding: 8 }}>
            <Text style={{ fontSize: 16 }}>10M users using</Text>
            <LogoIcon color={colors.theme} height={28} />
            <Text style={{ fontSize: 16 }}>. This app ...</Text>
          </View>
        ),
      },
    ]

    return (
      <View>
        <HeaderCatch
          title={i18n.t`Brand guidelines`}
          headerText={i18n.t`How to use Cacicolle logo`}
          description={i18n.t`This page describes usage and restrictions of Cacicolle branding assets`}
        />
        <Section
          title={i18n.t`Logo`}
          description={i18n.t`When you use the Cacicolle branding assets include logo mark, please follow the rules described this page.`}
        />
        <Section
          title={i18n.t`Colors`}
          description={i18n.t`Color of the logo must be follows.`}
        >
          <Row
            style={{
              marginBottom: 36,
              flexDirection: isLargeScreen ? 'row' : 'column-reverse',
            }}
          >
            <Row style={{ margin: -8, flex: 1 }}>
              {[
                <ColorCard name="Cacicolle orange" colorCode={colors.theme} />,
                <ColorCard
                  name="Cacicolle orange2"
                  colorCode={colors.highOrange}
                />,
                <ColorCard name="White" colorCode={colors.white} border />,
              ].map((element, index) => (
                <View key={index} style={{ margin: 8 }}>
                  {element}
                </View>
              ))}
            </Row>
            <Text style={{ color: colors.black80, marginBottom: 16, flex: 1 }}>
              <Trans>Colors for Logo mark</Trans>
            </Text>
          </Row>
          <Row
            style={{
              marginBottom: 28,
              flexDirection: isLargeScreen ? 'row' : 'column-reverse',
            }}
          >
            <Row style={{ margin: -8, flex: 1 }}>
              {[
                <ColorCard name="Gray" colorCode={colors.black80} border />,
              ].map((element, index) => (
                <View key={index} style={{ margin: 8 }}>
                  {element}
                </View>
              ))}
            </Row>
            <Text style={{ color: colors.black80, marginBottom: 16, flex: 1 }}>
              <Trans>Colors for Text</Trans>
            </Text>
          </Row>
        </Section>
        <Section
          title={i18n.t`Sizing & Spacing`}
          description={i18n.t`When you use the Cacicolle logo, the size must be bigger than "minimum size", and put spaces between the logo and other contents.`}
        >
          <Row style={{ marginBottom: 40 }}>
            <View style={{ flex: 1, minWidth: 270 }}>
              <Text style={{ color: colors.black80 }}>
                <Trans>
                  The minimum vertical spacing is 1 / 3 of the height. The
                  minimum horizontal spacing is the spacing when put logo in
                  center of a "5 / 3 of height" square. See the following image.
                </Trans>
              </Text>
            </View>
            <View style={{ flex: 1, alignItems: 'center', minWidth: 270 }}>
              <Img
                {...data.logoGuide}
                style={{
                  width: logoGuideImageSize,
                  height: logoGuideImageSize,
                  maxWidth: '100%',
                  maxHeight: '100%',
                }}
              />
            </View>
          </Row>
          <Row>
            <View style={{ flex: 1, minWidth: 270 }}>
              <Text style={{ color: colors.black80 }}>
                <Trans>"60px" is the minimum size of logo area</Trans>
              </Text>
            </View>
            <Row style={{ flex: 1, minWidth: 270, justifyContent: 'center' }}>
              <Logo
                size={60}
                backgroundColor={colors.white}
                color={colors.theme}
                border
              />
              {isLargeScreen && <Space width={8} />}
              <Logo size={60} />
            </Row>
          </Row>
        </Section>
        <Section
          title={i18n.t`Allowed combinations`}
          description={i18n.t`These are some examples of allowed usage`}
        >
          <Row>
            <View style={{ flex: 1, minWidth: 270 }}>
              <h3>
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: '400',
                    color: colors.black40,
                  }}
                >
                  <Trans>Without border</Trans>
                </Text>
              </h3>
            </View>
            <View style={{ flex: 1, minWidth: 270 }}>
              <Row style={{ margin: -10, flexWrap: 'wrap' }}>
                {[
                  <Logo
                    size={150}
                    backgroundColor={colors.white}
                    color={colors.theme}
                    border
                  />,
                  <Logo size={150} />,
                  <Logo size={150} WrapperComponent={BackGroundGradation} />,
                  <Logo
                    size={150}
                    WrapperComponent={BackGroundGradation}
                    colors={['#a24600', '#FF8E00']}
                  />,
                ].map((element, index) =>
                  React.cloneElement(element, {
                    style: { margin: 10 },
                    size: logoIconSize,
                    key: index,
                  }),
                )}
              </Row>
            </View>
          </Row>
          <Row style={{ marginTop: 40 }}>
            <View style={{ flex: 1, minWidth: 270 }}>
              <h3>
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: '400',
                    color: colors.black40,
                  }}
                >
                  <Trans>With border</Trans>
                </Text>
              </h3>
              <Text style={{ marginTop: 20, color: colors.black80 }}>
                <Trans>* The border radius is 15% of logo area</Trans>
              </Text>
            </View>
            <View style={{ flex: 1, minWidth: 270 }}>
              <Row style={{ margin: -10, flexWrap: 'wrap' }}>
                {[
                  <Logo
                    size={150}
                    backgroundColor={colors.white}
                    color={colors.theme}
                    border
                    borderRadius
                  />,

                  <Logo size={150} borderRadius />,
                  <Logo
                    size={150}
                    WrapperComponent={BackGroundGradation}
                    borderRadius
                  />,
                  <Logo
                    size={150}
                    WrapperComponent={BackGroundGradation}
                    colors={['#a24600', '#FF8E00']}
                    borderRadius
                  />,
                ].map((element, index) =>
                  React.cloneElement(element, {
                    style: { margin: 10 },
                    size: logoIconSize,
                    key: index,
                  }),
                )}
              </Row>
            </View>
          </Row>
        </Section>
        <Section
          title={i18n.t`Common Errors`}
          description={i18n.t`These are some examples of common mistakes.`}
        >
          <Row style={!isLargeScreen && { flexDirection: 'column-reverse' }}>
            <View style={{ flex: 2 }}>
              <Text style={{ color: colors.black40 }}>Do not...</Text>
              <ul style={{ paddingLeft: 40, fontSize: 14 }}>
                {doNotListData.map((item) => (
                  <li key={item.text} style={{ color: colors.black80 }}>
                    <Text style={{ color: colors.black80, fontWeight: '400' }}>
                      {item.text}
                    </Text>
                  </li>
                ))}
              </ul>
            </View>
            <Space {...(!isLargeScreen ? { height: 60 } : { width: 60 })} />
            <View style={{ flex: 3, minWidth: 270 }}>
              <DoNotView
                style={{
                  margin: -doNotListMargin,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {doNotList.map(({ title, element, style }, i) => (
                  <View
                    style={{
                      margin: doNotListMargin,
                    }}
                    key={`${title}${i}`}
                  >
                    <View
                      style={[
                        {
                          borderWidth: 1,
                          borderColor: '#DDD',
                          width: 150,
                          height: 150,
                          justifyContent: 'center',
                          alignItems: 'center',
                        },
                        style,
                      ]}
                    >
                      {element}
                    </View>
                    <Text style={{ color: colors.black40 }}>{title}</Text>
                  </View>
                ))}
              </DoNotView>
            </View>
          </Row>
        </Section>
        <Section
          title={i18n.t`Download`}
          description={i18n.t`Here is ready to use assets.`}
        >
          <View style={{ alignItems: 'flex-start' }}>
            <Link
              to="/download/cacicolle-logo-assets.zip"
              download="cacicolle-logo-assets.zip"
            >
              <Button
                type="text"
                title={i18n.t`Download All Assets`}
                // onPress={() => {
                //   navigate('/download/cacicolle-logo-assets.zip')
                // }}
                style={{
                  backgroundColor: colors.theme,
                  paddingVertical: 8,
                  paddingHorizontal: 16,
                  borderRadius: 5,
                }}
                hoverStyle={{
                  backgroundColor: colors.white,
                  paddingVertical: 8,
                  paddingHorizontal: 16,
                  borderRadius: 5,
                  borderWidth: 1,
                  borderColor: '#DDD',
                  margin: -1,
                }}
                textStyle={{ color: colors.white, fontSize: 20 }}
                hoverTextStyle={{
                  color: colors.theme,
                  fontSize: 20,
                }}
              />
            </Link>
          </View>
        </Section>
      </View>
    )
  }),
)
export default BrandGuidelinesPage

export const pageQuery = graphql`
  query BrandGuidelinesQuery($language: String!) {
    logoGuide: imageSharp(
      fluid: { originalName: { regex: "/logo-guide.png/" } }
    ) {
      fluid(maxWidth: 150) {
        # ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluid_noBase64
      }
    }
    ...FooterFragment
  }
`
