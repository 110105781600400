import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { withMetricsProps, withMetrics } from '@src/design/withMetrics'
import { colors } from '@src/theme/colors'

interface SectionProps extends withMetricsProps {
  title: string
  description?: string
}

@(withMetrics as any)
export class Section extends React.PureComponent<SectionProps> {
  static defaultProps = {
    // to avoid error from decorator
    // TODO: fix type definition for decorators
    theme: undefined,
  }
  render() {
    const { title, description, children, theme } = this.props
    return (
      <View
        style={theme.windowSizeKey > 1 ? styles.containerX : styles.container}
      >
        <Text style={styles.Title}>{title}</Text>
        {description && <Text style={styles.Description}>{description}</Text>}
        <View style={styles.content}>{children}</View>
      </View>
    )
  }
}
const styles = StyleSheet.create({
  containerX: {
    borderTopWidth: 1,
    borderTopColor: '#DDD',
    paddingHorizontal: 160,
    paddingTop: 60,
    paddingBottom: 60,
  },
  container: {
    borderTopWidth: 1,
    borderTopColor: '#DDD',
    paddingHorizontal: '5%',
    paddingTop: 60,
    paddingBottom: 60,
  },
  content: {
    marginTop: 40,
  },
  Title: {
    fontSize: 24,
  },
  Description: {
    marginTop: 28,
    color: colors.black80,
  },
})
